import { request } from "../../api/api";

export const getRawMaterialDetails = (searchParams) => {
  const queryParams = Object.entries({
    search: searchParams.search.Date,
    compound_name: searchParams.search.compound_name,
    batch_number: searchParams.search.batch_number,
    lot_no: searchParams.search.lot_no,
    recipe_name: searchParams.search.recipe_name,
    recipe_description: searchParams.search.recipe_description,
    batch_weight: searchParams.search.batch_weight,
    start_time: searchParams.search.start_time,
    end_time: searchParams.search.end_time,
    operator_name__username: searchParams.search.operator_name__username,
    supervisor_name__username: searchParams.search.supervisor_name__username,
    qa_supervisor_name__username:
      searchParams.search.qa_supervisor_name__username,
    page: searchParams.page + 1,
    page_size: searchParams.rowsPerPage,
  })
    .filter(([, value]) => value !== undefined && value !== "") // Filter out undefined or empty values
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`) // Encode key-value pairs
    .join("&");

  return request.get(`/raw_material/getRawMaterialDetails/?${queryParams}`);
};

export const updateRawMaterialDetails = (id, data)=>{
    return request.put(`/raw_material/updateRawMaterialDetails/${id}/`, data);
}

export const getRawMaterialReports = (id) => {
  return request.get(`/raw_material/getRawMaterialReportDetails/${id}`);
}

export const createRawMaterialReportStatus = (data) => {
  return request.post(`/raw_material/createRawMaterialReportStatus/`, data);
}

export const generateRawMaterialReportPDF = (data) => {
  return request.post(`/raw_material/generateRawMaterialReportPDF/`, data);
}