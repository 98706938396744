import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./SFGstore.css";

const SFGstore = () => {
  const data = [
    { class: "00", size: 3, length: 380, type: "SC", quantity: 825 },
    { class: "1", size: 9, length: 420, type: "RE", quantity: 760 },
    { class: "2", size: 10, length: 380, type: "SC", quantity: 825 },
    { class: "3", size: 11, length: 420, type: "RE", quantity: 760 },
  ];

  const navigate = useNavigate();
  return (
    <div className="dashboard-wrapper page-wraper">
      <div className="page-header">
        <Link to="/dashboard" className="page-back-btn">
          <ArrowBackIcon />
          <span>SFG Store</span>
        </Link>
        <button
          className="page-header-btn-params"
          style={{ fontSize: "17px" }}
          onClick={() => navigate("/sfg-history")}
        >
          SFG History
        </button>
      </div>
      <div className="pt-3 table-responsive">
        <TableContainer component={Paper}>
          <Table className="sfg-score-table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#1c447c" }}>
                <TableCell style={{ color: "#fff" }}>Class</TableCell>
                <TableCell style={{ color: "#fff" }}>Size</TableCell>
                <TableCell style={{ color: "#fff" }}>Length</TableCell>
                <TableCell style={{ color: "#fff" }}>Type</TableCell>
                <TableCell style={{ color: "#fff" }}>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  style={index % 2 ? { backgroundColor: "#f9f9f9" } : null}
                >
                  <TableCell>{row.class}</TableCell>
                  <TableCell>{row.size}</TableCell>
                  <TableCell>{row.length}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SFGstore;
