import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  IconButton,
  Dialog,
  Button,
  Checkbox
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../config/images";
import { createRawMaterialReportStatus, generateRawMaterialReportPDF, getRawMaterialReports } from "./services";
import dayjs from "dayjs";
import { useSelector } from "react-redux";


const RawMaterialReport = ({ row }) => {
  const [reportData, setReportData] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [remark, setRemark] = useState("");
  const {
    user_role,
    user_role_name,
    username,
  } = useSelector((state) => state.userState);



  const data = [
    {
      no: "Sr No.",
      data1: "1",
      data2: "1",
      data3: "1",
      data4: "1",
      data5: "1",
      data6: "1",
      data7: "1",
      data8: "1",
      data9: "1",
      data10: "1",
      data11: "1",
    },
    {
      no: "Chemical Code",
      data1: "1",
      data2: "1",
      data3: "1",
      data4: "1",
      data5: "1",
      data6: "1",
      data7: "1",
      data8: "1",
      data9: "1",
      data10: "1",
      data11: "1",
    },
    {
      no: "Set Weight (kg)",
      data1: "1",
      data2: "1",
      data3: "1",
      data4: "1",
      data5: "1",
      data6: "1",
      data7: "1",
      data8: "1",
      data9: "1",
      data10: "1",
      data11: "1",
    },
    {
      no: "Net Weight (kg)",
      data1: "1",
      data2: "1",
      data3: "1",
      data4: "1",
      data5: "1",
      data6: "1",
      data7: "1",
      data8: "1",
      data9: "1",
      data10: "1",
      data11: "1",
    },
    {
      no: "Shift & Time",
      data1: "1",
      data2: "1",
      data3: "1",
      data4: "1",
      data5: "1",
      data6: "1",
      data7: "1",
      data8: "1",
      data9: "1",
      data10: "1",
      data11: "1",
    },
  ];

  const rows = [
    { label: "Sr No.", key: "sr_no" },
    { label: "Chemical Code", key: "chemical_code" },
    { label: "Set Weight (kg)", key: "set_weight" },
    { label: "Net Weight (kg)", key: "net_weight" },
    { label: "Shift & Time", key: "shift_name" },
  ];

  const params = useParams();

  const getData = async () => {

    const id = params.id

    try {
      const response = await getRawMaterialReports(id);

      if (response.status === 200) {
        setReportData(response.data)
      }
    } catch (error) {
      console.log("Error geting data")
    }
  };
  useEffect(() => {
    getData()
  }, [])


  const getFormattedDateTime = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const handleOpen = (type) => {
    setDialogType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogType("");
    setRemark("");
  };

  const handleUpdate = async () => {

    if (!remark) {
      toast.error("Please Enter remark.");
      return false;
    }
    const id = params.id
    const data = {
      "material_id": id,
      "status": dialogType === "approve" ? 0 : 1,
      "remarks": remark

    }

    try {
      const response = await createRawMaterialReportStatus(data);
      if (response.status === 200 || response.status === 201) {
        toast.success("Raw material details updated successfully!");
        setOpen(false); // Close the dialog
        getData(); // Refresh the data
      }
    } catch (error) {
      console.log("error updating remark", error);
      toast.error("Failed to update raw material details. Please try again.");
    }
  }

  const handlePDFClick = async() =>{
    const param ={
      material_id : params.id 
    }
    try{
      const resp = await generateRawMaterialReportPDF(param)
      if(resp.status == 200 || resp.status == 201){
        toast.success(resp.data.message)
      }
    }
    catch(error){
      toast.error(error.response.data.message)
      console.log("handlesubmit",error)
    }
  }

  return (
    <>
   
      <div className="dashboard-wrapper page-wraper">
        <ToastContainer />
        <div className="page-header">
          <Link to="/raw-material" className="page-back-btn">
            <ArrowBackIcon />
            <span>Report</span>
          </Link>
          <div className="header-btn-group">
            <img src={images.PDFDownload} alt="" onClick={handlePDFClick} style={{cursor:"pointer"}}/>
            {/* <button className="page-header-btn">
              <PictureAsPdfIcon />
            </button> */}
          </div>
        </div>

        <div className="d-block">
          <Table className="sfg-score-table raw-material-data mt-3">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Compound Name</TableCell>
                <TableCell>Batch Number</TableCell>
                <TableCell>Lot No.</TableCell>
                <TableCell>Recipe Name</TableCell>
                <TableCell>Recipe Desc.</TableCell>
                <TableCell>Batch Weight</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Date"
                    name="Date"
                  //   value={searchText.B_No}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Time"
                    name="Time"
                  //   value={searchUID}
                  //   onChange={handleUIDSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Time"
                    name="Time"
                  //   value={searchText.Lot_No}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Compound Name"
                    name="Compound Name"
                  //   value={searchText.Class}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Batch no."
                    name="Batch no."
                  //   value={searchText.Size}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Lot No."
                    name="Lot No."
                  //   value={searchText.Scre}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  {" "}
                  <input
                    type="search"
                    placeholder="Reciepe Name"
                    name="Reciepe Name"
                  //   value={searchText.Scre}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <input
                    type="search"
                    placeholder="Receipe Desc."
                    name="Receipe Desc."
                  //   value={searchText.Scre}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <input
                    type="search"
                    placeholder="Batch Weight"
                    name="Batch Weight"
                  //   value={searchText.Scre}
                  //   onChange={handleSearchData}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell> {" "}{dayjs(reportData.created_at).format("YYYY-MM-DD")}</TableCell>
                <TableCell>{reportData.start_time}</TableCell>
                <TableCell>{reportData.end_time}</TableCell>
                <TableCell>{reportData.compound_name}</TableCell>
                <TableCell>{reportData.batch_number}</TableCell>
                <TableCell>{reportData.lot_no}</TableCell>
                <TableCell>{reportData.recipe_name}</TableCell>
                <TableCell>{reportData.recipe_description}</TableCell>
                <TableCell>{reportData.batch_weight}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={9} style={{background:"white"}}>
                  <Box>
                    <Table className="sub-data-table">
                      <TableBody>
                        {rows.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {/* Render the fixed row label */}
                            <TableCell>
                              <b>{row.label}</b>
                            </TableCell>

                            {/* Dynamically render columns for each report detail */}
                            {reportData.report_details?.map((detail, colIndex) => (
                              <TableCell key={colIndex}>
                                {detail[row.key]} {/* Access dynamic data using the key */}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box className="pt-5">
                    <Table className="sub-data-table designation-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>User</TableCell>
                          <TableCell>Designation</TableCell>
                          <TableCell>Date & Time</TableCell>
                          <TableCell>Approved Status</TableCell>
                          <TableCell>Remarks</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Map over reportData.statuses to display rows with existing data */}
                        {reportData.statuses?.map((status, index) => (
                          <TableRow key={index}>
                            <TableCell>{status.username}</TableCell>
                            <TableCell>{status.user_role}</TableCell>
                            <TableCell> {getFormattedDateTime(status.created_at)}</TableCell>
                            <TableCell className="user-approved-status">
                              <div
                                className="approval-buttons"
                                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                              >
                                {status.status_display === "Approved" ? (
                                  <button
                                    className="approve-button"
                                    disabled
                                    style={{
                                      backgroundColor: "#CFFFEC",
                                      color: "#00AB66",
                                      border: "none",
                                      padding: "5px 10px",
                                      borderRadius: "6px",
                                      fontSize:"14px"
                                    }}
                                  >
                                    Approved
                                  </button>
                                ) : status.status_display === "Rejected" ? (
                                  <button
                                    className="reject-button"
                                    disabled
                                    style={{
                                      backgroundColor: "#FFCECF",
                                      color: "#E31E24",
                                      border: "none",
                                      padding: "5px 10px",
                                      borderRadius: "6px",
                                      fontSize:"14px"
                                    }}
                                  >
                                    Rejected
                                  </button>
                                ) : null}
                              </div>
                            </TableCell>
                            <TableCell>{status.remarks}</TableCell>
                          </TableRow>
                        ))}
                        {/* Add an additional row for current user */}
                        {!reportData.statuses?.some((status) => status.user_role === user_role_name) && (
                          <TableRow>
                            <TableCell>{username}</TableCell>
                            <TableCell>{user_role_name}</TableCell>
                            <TableCell>{getFormattedDateTime(new Date())}</TableCell>
                            <TableCell className="user-approved-status">
                              <div className="approval-buttons">
                                <button
                                  className="approve-button"
                                  onClick={() => handleOpen("approve")}
                                  disabled={
                                    (user_role_name === "Supervisor" &&
                                      !reportData.statuses?.some((status) => status.user_role === "Operator")) ||
                                    (user_role_name === "QA Supervisor" &&
                                      (!reportData.statuses?.some((status) => status.user_role === "Operator") ||
                                        !reportData.statuses?.some((status) => status.user_role === "Supervisor")))
                                  }
                                  style={{
                                    cursor:
                                      (user_role_name === "Supervisor" &&
                                        !reportData.statuses?.some((status) => status.user_role === "Operator")) ||
                                        (user_role_name === "QA Supervisor" &&
                                          (!reportData.statuses?.some((status) => status.user_role === "Operator") ||
                                            !reportData.statuses?.some((status) => status.user_role === "Supervisor")))
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Approve
                                </button>
                                <button
                                  className="reject-button"
                                  onClick={() => handleOpen("reject")}
                                  disabled={
                                    (user_role_name === "Supervisor" &&
                                      !reportData.statuses?.some((status) => status.user_role === "Operator")) ||
                                    (user_role_name === "QA Supervisor" &&
                                      (!reportData.statuses?.some((status) => status.user_role === "Operator") ||
                                        !reportData.statuses?.some((status) => status.user_role === "Supervisor")))
                                  }
                                  style={{
                                    cursor:
                                      (user_role_name === "Supervisor" &&
                                        !reportData.statuses?.some((status) => status.user_role === "Operator")) ||
                                        (user_role_name === "QA Supervisor" &&
                                          (!reportData.statuses?.some((status) => status.user_role === "Operator") ||
                                            !reportData.statuses?.some((status) => status.user_role === "Supervisor")))
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Reject
                                </button>
                              </div>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                      </TableBody>

                    </Table>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <Dialog open={open} onClose={handleClose} className="custom-dialog">
          <div className="dialog-fields-container">
            <h3>Remarks</h3>
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Comment here"
                variant="outlined"
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
            <div className="dialog-fields-actions">
              <Button onClick={handleClose} variant="outlined" style={{ width: "50%" }}>
                Cancel
              </Button>
              <button
                onClick={handleUpdate}
                style={{ width: "50%" }}
              >
                {dialogType === "approve" ? "Approve" : "Reject"}
              </button>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="d-flex justify-content-end aborted">
        <p style={{ margin: 0, marginBottom: 0 }}>
          Powered by <b>Hikar&#174;Technomation</b> Private Limited &#169; All
          Rights Reserved
        </p>
      </div>
    </>
  );
};

export default RawMaterialReport;
