import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { images } from "../../config/images";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Checkbox
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "../RawMaterial/RawMaterial.css";
import { getRawMaterialDetails, updateRawMaterialDetails } from "./services";
import dayjs from "dayjs";

const RawMaterial = () => {
  const navigate = useNavigate();
  const [debouncedSearchText, setDebouncedSearchText] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedOrderType, setSelectedOrderType] = useState();
  const [getMaterialData, setGetMaterialData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    lotNo: "",
    freshLatexVelocity: "",
    viscosityAfterComp: "",
    mTank: "",
    rpm: "",
    remarks: "",
    id : ""
  });

  const initiallValue = {
    Date: "",
    start_time: "",
    end_time: "",
    compound_name: "",
    batch_number: "",
    lot_no: "",
    recipe_name: "",
    recipe_description: "",
    batch_weight: "",
    operator_name__username: "",
    supervisor_name__username: "",
    qa_supervisor_name__username: "",
  };

  const [searchText, setSearchText] = useState(initiallValue);

  useEffect(() => {
    if (selectedOrderType !== "all") {
      handleOrderTypeChange();
    } else {
      const params = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchText: searchText,
      };
      // fetchOrderDetailTypeData(params);
    }
  }, [selectedOrderType]);

  const handleOrderTypeChange = async (event) => {
    if (!selectedOrderType) {
      return true;
    }

    const params = {
      order_type: selectedOrderType,
    };
    // try {
    //     const resp = await getOrderbyType(params);
    //     if (resp.status === 200 || resp.status === 201) {
    //         setOrderDetail(resp.data.results);
    //     }
    // } catch (error) {
    //     console.log("handleSubmit", error);
    // }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText); // Update the debounced searchText
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(handler); // Cleanup the timeout
    };
  }, [searchText]); // Effect runs when searchText changes

  const handleSearchData = async (e) => {
    // Destructure the name and value of the input field that triggered the change
    const { name, value } = e.target;

    // Update the searchText state object with the new value for the specified input field
    setSearchText({ ...searchText, [name]: value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleEditClick = (row) => {
    setEditData({
      id: row.id,
      lotNo: row.lot_no,
      freshLatexVelocity: row.fresh_latex_velocity || "",
      viscosityAfterComp: row.viscosity_after_comp || "",
      mTank: row.m_tank || "",
      rpm: row.rpm || "",
      remarks: row.remarks || "",
    });
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setEditData({ ...editData, [field]: value });
  };

  const handleUpdate = async () => {

    try {
      // Extract the ID from the editData or pass it as part of the row data
      const id = editData.id; // Ensure the `id` is part of the `editData` state or passed explicitly
  
      const payload = {
        lot_no: editData.lotNo,
        fresh_latex_velocity: parseFloat(editData.freshLatexVelocity),
        viscosity_after_comp: parseFloat(editData.viscosityAfterComp),
        m_tank: editData.mTank,
        rpm: editData.rpm,
        remarks: editData.remarks,
      };
      const response = await updateRawMaterialDetails(id, payload);
  
      if (response.status === 200 || response.status === 201) {
        toast.success("Raw material details updated successfully!");
        setOpen(false); // Close the dialog
        handleGetMaterialData(); // Refresh the data
      } else {
        throw new Error("Failed to update raw material details");
      }
    } catch (error) {
      console.error("Error updating raw material details:", error);
      toast.error("Failed to update raw material details. Please try again.");
    }
  };

  useEffect(() => {
    if (Object.keys(debouncedSearchText).length > 0) {
      handleGetMaterialData();
    }
  }, [page, rowsPerPage, debouncedSearchText]);
  

  const handleGetMaterialData = async () => {
    const searchParams = {
      search: debouncedSearchText,
      page: page,
      rowsPerPage: rowsPerPage,
    };
    try {
      const resp = await getRawMaterialDetails(searchParams);
      if (resp.status == 200 || resp.status == 201) {
        setGetMaterialData(resp.data.payload);
      }
    } catch (error) {
      setGetMaterialData([]);
      console.log("handlesubmit", error);
    }
  };
  const handleClick = (event, name, row) => {
  }

  return (
    <>
      <div className="dashboard-wrapper page-wraper">
        <ToastContainer />
        <div className="page-header">
          <Link to="/dashboard" className="page-back-btn">
            <ArrowBackIcon />
            <span>Raw Material</span>
          </Link>
        </div>
        <div className="table-responsive" style={{}}>
          <Table className="productionTable mt-3">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Date</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Compound Name</TableCell>
                <TableCell>Batch Number</TableCell>
                <TableCell>Lot No.</TableCell>
                <TableCell>Recipe Name</TableCell>
                <TableCell>Recipe Desc.</TableCell>
                <TableCell>Batch Weight</TableCell>
                <TableCell>Operator Name</TableCell>
                <TableCell>Supervisor Name</TableCell>
                <TableCell>QA Sup. Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Date"
                    name="Date"
                    value={searchText.Date}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Time"
                    name="start_time"
                    value={searchText.start_time}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Time"
                    name="end_time"
                    value={searchText.end_time}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Compound Name"
                    name="compound_name"
                    value={searchText.compound_name}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Batch Number"
                    name="batch_number"
                    value={searchText.batch_number}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Lot No."
                    name="lot_no"
                    value={searchText.lot_no}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Recipe Name"
                    name="recipe_name"
                    value={searchText.recipe_name}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Recipe Desc"
                    name="recipe_description"
                    value={searchText.recipe_description}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Batch Weight"
                    name="batch_weight"
                    value={searchText.batch_weight}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Operator Name"
                    name="operator_name__username"
                    value={searchText.operator_name__username}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Supervisor Name"
                    name="supervisor_name__username"
                    value={searchText.supervisor_name__username}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="QA Sup Name"
                    name="qa_supervisor_name__username"
                    value={searchText.qa_supervisor_name__username}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Action"
                    name="Action"
                  // value={searchText.Action}
                  // onChange={handleSearchData}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getMaterialData?.results?.map((row) => {
                return (
                  <TableRow
                    role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                  // selected={isItemSelected}
                  >
                    <TableCell padding="checkbox"
                      style={{ pointerEvents: "all" }}
                    >
                      <Checkbox
                        onClick={(event) =>
                          handleClick(event, row.id, row)
                        }
                        color="primary"
                      // checked={isItemSelected}
                      // disabled={isCheckboxDisabled(row.batchid)}
                      // inputProps={{
                      //   "aria-labelledby": labelId,
                      // }}
                      />
                    </TableCell>
                    <TableCell>
                      {" "}
                      {dayjs(row.created_at).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>{row.start_time}</TableCell>
                    <TableCell>{row.end_time}</TableCell>
                    <TableCell>{row.compound_name}</TableCell>
                    <TableCell>{row.batch_number}</TableCell>
                    <TableCell>{row.lot_no}</TableCell>
                    <TableCell>{row.recipe_name}</TableCell>
                    <TableCell>{row.recipe_description}</TableCell>
                    <TableCell>{row.batch_weight}</TableCell>
                    <TableCell>{row.operator_name ? row.operator_name : "-"}</TableCell>
                    <TableCell>{row.supervisor_name ? row.supervisor_name : "-"}</TableCell>
                    <TableCell>{row.qa_supervisor_name ? row.qa_supervisor_name : "-"}</TableCell>

                    <TableCell className="raw-material-btn-column">
                      <img
                        style={{ width: "26px" }}
                        src={images.editIcon}
                        onClick={() => handleEditClick(row)}
                        // value={robotHistoryData}
                        className="edit-btn"
                        alt=""
                      />
                      <img
                        style={{ width: "26px" }}
                        src={images.reportIcon}
                        onClick={() => navigate(`/raw-material-report/${row.id}`)}
                        // value={robotHistoryData}
                        className="edit-btn"
                        alt=""
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          className="table-pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={getMaterialData ? getMaterialData.count : null}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Dialog open={open} onClose={handleClose} className="custom-dialog">
          <div className="dialog-fields-container">
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                label="Lot No"
                value={editData.lotNo}
                variant="filled"
                onChange={(e) => handleChange("lotNo", e.target.value)}
              />
              <img
                src={images.editIcon}
                alt="edit icon"
                onClick={(e) => handleChange("lotNo", e.target.value)}
                className="editIcon"
              />
            </div>
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                label="Fresh Latex Velocity"
                value={editData.freshLatexVelocity}
                variant="filled"
                onChange={(e) =>
                  handleChange("freshLatexVelocity", e.target.value)
                }
              />
              <img src={images.editIcon} alt="edit icon" className="editIcon" />
            </div>
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                label="Viscosity After Comp."
                value={editData.viscosityAfterComp}
                variant="filled"
                onChange={(e) =>
                  handleChange("viscosityAfterComp", e.target.value)
                }
              />
              <img src={images.editIcon} alt="edit icon" className="editIcon" />
            </div>
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                label="M. Tank"
                value={editData.mTank}
                variant="filled"
                onChange={(e) => handleChange("mTank", e.target.value)}
              />
              <img src={images.editIcon} alt="edit icon" className="editIcon" />
            </div>
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                label="RPM"
                value={editData.rpm}
                variant="filled"
                onChange={(e) => handleChange("rpm", e.target.value)}
              />
              <img src={images.editIcon} alt="edit icon" className="editIcon" />
            </div>
            <div className="textFieldWrapper">
              <TextField
                fullWidth
                label="Remarks"
                value={editData.remarks}
                variant="filled"
                multiline
                onChange={(e) => handleChange("remarks", e.target.value)}
              />
              <img src={images.editIcon} alt="edit icon" className="editIcon" />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              className="custom-update-button"
            >
              Update
            </Button>
          </div>
        </Dialog>
      </div>
      <div className="d-flex justify-content-end aborted">
        <p style={{ margin: 0, marginBottom: 0 }}>
          Powered by <b>Hikar&#174;Technomation</b> Private Limited &#169; All
          Rights Reserved
        </p>
      </div>
    </>
  );
};

export default RawMaterial;
