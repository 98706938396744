import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./SFGstore.css";
import COCDatePicker from "../../components/DateTimePicker/DateTimePicker";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";

const SFGHistory = () => {
  const data = [
    { class: "00", size: 3, length: 380, type: "SC", quantity: 825 },
    { class: "1", size: 9, length: 420, type: "RE", quantity: 760 },
    { class: "2", size: 10, length: 380, type: "SC", quantity: 825 },
    { class: "3", size: 11, length: 420, type: "RE", quantity: 760 },
  ];

  const navigate = useNavigate();
  const [showTime, setShowTime] = useState(false);
  const [startDateReFormat, setStartDateReFormat] = useState(null);
  const [endDateReFormat, setEndDateReFormat] = useState(null);

  const handleToggleTime = () => {
    setShowTime(!showTime);
  };
  const handleStartDate = (newStartDate) => {
    console.log("newStartDate", newStartDate);

    setStartDateReFormat(newStartDate);
  };

  const handleEndDate = (newEndDate) => {
    if (showTime) {
      setEndDateReFormat(newEndDate);
    } else {
      setEndDateReFormat(dayjs(newEndDate).hour(23).minute(59).second(0));
    }
  };

  const rows = [
    {
      bNo: "I0001",
      dateTime: "02/07/2022 12:30",
      aging: "28 days",
      boxNo: "SFGB01",
      class: "00",
      lotNo: "L042.08",
      size: "9R",
      length: 420,
      type: "SC",
      quantity: 1024,
    },
    {
      bNo: "I0002",
      dateTime: "02/07/2022 10:30",
      aging: "77 days",
      boxNo: "SFGB02",
      class: "1",
      lotNo: "L043.09",
      size: "9R",
      length: 380,
      type: "RE",
      quantity: 560,
    },
  ];

  return (
    <div className="dashboard-wrapper page-wraper">
      <div className="page-header">
        <Link to="/SFG-score" className="page-back-btn">
          <ArrowBackIcon />
          <span>SFG History</span>
        </Link>
        <div className="d-flex">
          <h4 className="d-flex mt-3">
            Total <b style={{ marginLeft: "10px" }}>1584</b>
          </h4>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className="time-picker"
              value="top"
              control={<Switch color="primary" checked={showTime} />}
              label="Time"
              labelPlacement="top"
              onChange={handleToggleTime}
            />
          </FormGroup>

          <COCDatePicker
            time={showTime}
            startDateReFormat={startDateReFormat}
            endDateReFormat={endDateReFormat}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            Start_Date_lable={"Start Date"}
            end_Date_lable={"End Date"}
          />
        </div>
        <div className="pt-3 table-responsive">
          <Table
            aria-label="Dipping parameter"
            className="dipping-params sfg-score-table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    //   onClick={(event) => handleSelectAllClick(event)}
                  />
                </TableCell>

                <TableCell>B.No</TableCell>
                <TableCell>Date and Time</TableCell>
                <TableCell>Aging</TableCell>
                <TableCell>Box No</TableCell>
                <TableCell>Class</TableCell>
                <TableCell>LOT No</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Length</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="B.No"
                    name="B.No"
                    //   value={searchText.Date}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Date and Time"
                    name="Date and Time"
                    //   value={searchText.B_No}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Aging"
                    name="Aging"
                    //   value={searchUID}
                    //   onChange={handleUIDSearch}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Box No"
                    name="Box No"
                    //   value={searchText.Lot_No}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Class"
                    name="Class"
                    //   value={searchText.Class}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Lot No"
                    name="Lot No"
                    //   value={searchText.Size}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Size"
                    name="Size"
                    //   value={searchText.Scre}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  {" "}
                  <input
                    type="search"
                    placeholder="Length"
                    name="Length"
                    //   value={searchText.Scre}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <input
                    type="search"
                    placeholder="Type"
                    name="Type"
                    //   value={searchText.Scre}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <input
                    type="search"
                    placeholder="Quantity"
                    name="Quantity"
                    //   value={searchText.Scre}
                    //   onChange={handleSearchData}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>{row.bNo}</TableCell>
                  <TableCell>{row.dateTime}</TableCell>
                  <TableCell>{row.aging}</TableCell>
                  <TableCell>{row.boxNo}</TableCell>
                  <TableCell>{row.class}</TableCell>
                  <TableCell>{row.lotNo}</TableCell>
                  <TableCell>{row.size}</TableCell>
                  <TableCell>{row.length}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SFGHistory;
